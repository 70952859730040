@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700;800;900&display=swap");
@font-face {
  font-family: Integral CF;
  src: url('../public/assets/fonts/integralcf/integralcf-regular.ttf');
}
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.formGlobal {
  width: 90%;
  max-width: 400px;
}
.big_loading_modal {
  background-color: rgba(255, 255, 255, 0.838);
  border: unset !important;
  position: fixed;
  top: 0px;
  right: 0px;
  bottom: 0px;
  left: 0px;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
}
.loading_gif {
  height: 150px;
}
.bigLoadingWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.arabic_direction {
  direction: ltr;
}
/* .english_direction {
  direction: rtl;
  text-align: left;
} */
.poppinsdesc {
  font-weight: 300 !important;
}

.react-tel-input .form-control {
  border: none !important;
}
.form-control:focus {
  box-shadow: none !important;
}

.react-tel-input .flag-dropdown {
  background-color: unset !important;
  border: none !important;
}


.MuiSlider-thumb {
  width: 15px !important;
  height: 20px !important;
  border-radius: 30% !important;
  color: black !important;
}

.MuiSlider-rail {
  opacity: 1 !important;
  background: linear-gradient(270deg, #1877F2 4.26%, #3CC8EB 103.62%) !important;
  border-radius: 3px !important;
  border: none !important;
}
.MuiSlider-root{
  height: 6px !important;
}

::-webkit-scrollbar {
  display: none;
}
.lng-modal .modal-content {
  background: linear-gradient(127.46deg, #9E17AA -26.33%, #2D083D 11.97%, #030314 49.05%, #0B0B43 85.3%, #1F1FB1 129.02%);  position: relative;
  overflow: hidden;
  /* width: 696px; */
  height: 540px;
}
.lng-modal .modal-content .bottom-image{
  position: absolute;
  bottom: 0px;
  right: 0px;
  z-index: 0;
}
.lng-modal .modal-content .top-image{
  position: absolute;
  top: 0px;
  left: 0px;
  z-index: 0;
}
.lang_btn {
  width: 204px;
  height: 63px;
  background: rgba(255, 255, 255, 0.2);
  backdrop-filter: blur(6.5px);
  border-radius: 32px;
  border: 2px solid #fff;
  color: #fff;
  font-size: 27px;
}
.lang_btn:hover {
  background: rgba(255, 255, 255, 0.019);
  transition: all 0.3s ease-in-out;
}


@media (max-width:900px) {
  .lng-modal .modal-content h1 {
    font-size: 22px !important;
  }
  .lang_btn {
    width: 99px;
  height: 46px;
  font-size: 16px;
  }
  .lng-modal .modal-content a h6 {
    font-size: 19px !important;
  }
  .lng-modal .modal-content a p {
    font-size: 10px !important;
  }
}
@media (max-width:500px) {
  .lng-modal .modal-content .mobileLinksWrapper a img {
    width: 125px;
  }
}
@media (max-width:290px) {
  .lng-modal .modal-content .mobileLinksWrapper {
    flex-wrap: wrap;
  }
}